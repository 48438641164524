@import "styles/_colors.scss"; @import "styles/_variables.scss"; @import "styles/_cmixins.scss";
.top_rated_nfts {
  padding: 32px 0;
  // display: flex;

  .header {
    display: flex;
    align-items: center;
    align-content: center;
    padding: 16px 0;
    @include responsive-up(xl) {
      padding: 16px 0;
    }
    .title {
      display: flex;
      flex: 0.95;
      font-family: $primary_font;
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 46px;
      color: $primary_font_color;
    }
    .slider_btns {
      display: flex;
      flex: 0.05;
      justify-content: flex-end;
      line-height: 46px;

      span {
        height: auto;
        svg {
          margin: 0 20px;

          &:last-child {
            margin: 0 0 0 20px;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }

      // &:nth-child(1){
      //     padding: 5px;
      //     font-size: 15px;
      // }

      // .arrow_right{
      //     padding: 5px;
      // }
    }
  }
  .listings {
    display: flex;
    flex-direction: row;
  }
}

.card__clr {
  margin: 0 0px 5px 0px !important;
}
.nodataavalabile {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.02em;
  font-style: normal;
}
